import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  privacyPolicy:any =[];
  constructor(
    private config_service : ConfigService
  ) { }
  ngOnInit(): void {
    this.config_service.getData().subscribe(data => {
      this.privacyPolicy = data.privacy_policy;
      console.log(this.privacyPolicy);
    });
  }


}
