<div class="about-wrapper">
  <app-headerhome class="header-sec"></app-headerhome>

    <!--start of about us section-->

<!-- ================================
  START BREADCRUMB AREA
================================= -->
<section class="breadcrumb-area bread-bg-9">
  <div class="breadcrumb-wrap">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="breadcrumb-content">
                      <div class="section-heading">
                          <h2 class="sec__title line-height-50 text-white">{{company_name}}                        </h2>
                      </div>
                  </div><!-- end breadcrumb-content -->
              </div><!-- end col-lg-12 -->
          </div><!-- end row -->
      </div><!-- end container -->
  </div><!-- end breadcrumb-wrap -->
  <div class="bread-svg-box">
      <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
  </div><!-- end bread-svg -->
</section><!-- end breadcrumb-area -->
<!-- ================================
  END BREADCRUMB AREA
================================= -->

<!-- ================================
  START INFO AREA
================================= -->

<!-- end info-area -->
<!-- ================================
  END INFO AREA
================================= -->

<!-- ================================
  START ABOUT AREA
================================= -->
<section class="about-area padding-bottom-90px padding-top-100px overflow-hidden">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <div class="section-heading margin-bottom-40px">
                  <h2 class="sec__title">About Us</h2>

                  <p *ngFor="let t of about_us" class="sec__desc font-size-16 pb-3">
                    {{t.text}}
                  </p>
                 
                </div><!-- end section-heading -->
          </div><!-- end col-lg-6 -->
          <div class="col-lg-5 ml-auto">
              <div class="image-box about-img-box">
                  <img src="{{about_bus_image}}" alt="about-img" class="img__item img__item-1">
                  <img src="{{about_seat_image}}" alt="about-img" class="img__item img__item-2">
              </div>
          </div><!-- end col-lg-5 -->
      </div><!-- end row -->
  </div><!-- end container -->
</section><!-- end about-area -->
<!-- ================================
  END ABOUT AREA
================================= -->

<!-- ================================
  STAR FUNFACT AREA
================================= -->
<section class="funfact-area padding-bottom-70px">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <div class="section-heading text-center">
                  <h2 class="sec__title">Our Numbers Say Everything</h2>
              </div><!-- end section-heading -->
          </div><!-- end col-lg-12 -->
      </div><!-- end row -->
      <div class="counter-box counter-box-2 margin-top-60px mb-0">
          <div class="row">
              <div class="col-lg-4 responsive-column">
                  <div class="counter-item counter-item-layout-2 d-flex">
                      <div class="counter-icon flex-shrink-0">
                          <i class="la la-building"></i>
                      </div>
                      <div class="counter-content">
                          <div>
                              <span class="counter" data-from="0" data-to="3"  data-refresh-interval="5">{{about_total_buses}}</span>
                              <span class="count-symbol">+</span>
                          </div>
                          <p class="counter__title">Buses</p>
                      </div><!-- end counter-content -->
                  </div><!-- end counter-item -->
              </div><!-- end col-lg-4 -->
              <div class="col-lg-4 responsive-column">
                  <div class="counter-item counter-item-layout-2 d-flex">
                      <div class="counter-icon flex-shrink-0">
                          <i class="la la-globe"></i>
                      </div>
                      <div class="counter-content">
                          <div>
                              <span class="counter" data-from="0" data-to="400"  data-refresh-interval="5">{{about_total_destinations}}</span>
                              <span class="count-symbol">+</span>
                          </div>
                          <p class="counter__title">Destinations</p>
                      </div><!-- end counter-content -->
                  </div><!-- end counter-item -->
              </div><!-- end col-lg-4 -->
              <div class="col-lg-4 responsive-column">
                  <div class="counter-item counter-item-layout-2 counter-item-layout-d d-flex">
                      <div class="counter-icon flex-shrink-0">
                          <i class="la la-check-circle"></i>
                      </div>
                      <div class="counter-content">
                          <div>
                              <span class="counter" data-from="0" data-to="40"  data-refresh-interval="5">{{about_bookings}}</span>
                              <span class="count-symbol"> Million+ </span>
                          </div>
                          <p class="counter__title">Bookings and counting</p>
                      </div><!-- end counter-content -->
                  </div><!-- end counter-item -->
              </div><!-- end col-lg-3 -->
          </div><!-- end row -->
      </div><!-- end counter-box -->
  </div><!-- end container -->
</section>
<!-- ================================
  END FUNFACT AREA
================================= -->

<app-footer></app-footer>

</div>
