import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
  about_us=[]
  about_total_buses=''
  about_total_destinations=''
  about_bookings=''
  about_seat_image=''
  about_bus_image=''
  company_name=""

  constructor(    private config_service : ConfigService) { }

  ngOnInit() {
    this.config_service.getData().subscribe(data => {
      this.company_name = data.company_name;
      this.about_us = data.about_us;
      this.about_total_buses = data.about_total_buses;
      this.about_total_destinations = data.about_total_destinations
      this.about_bookings = data.about_bookings;
      this.about_seat_image = data.about_seat_image
      this.about_bus_image = data.about_bus_image;

    });
  }

}
