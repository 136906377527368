import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {Destinations } from '../models/index'
import  { environment } from '../../environments/environment'
const httpOptions = {
  headers: new HttpHeaders({
    'Authorization':'Token ' + environment.api_key,
  })
};
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http:HttpClient
  ) { }

  getDestinations() {
    console.log(httpOptions)
    return this
            .http
            .get<Destinations[]>(`${environment.getDestinations}`,httpOptions);
  }
}
