const marketplaceUrl= 'https://marketplace-v2.buupass.com/marketplace/';
const homeUrl= 'https://transnumerik.buupass.com/api/v1/';
const homeUrlTrips= 'https://transnumerik.buupass.com/';

// const marketplaceUrl= 'https://dev.marketplaceapi.buupass.com/marketplace/'
  const v2P='https://pay-v2.buupass.com/payments/';
  const marketplaceUrlTrips= 'https://easycoach.buupass.com/';
  const usersApi='https://v2-user-management.buupass.com/user-management/users/';
  const usersApiLogin='https://v2-user-management.buupass.com/user-management/users/login';
  
export const environment = {
    // api_key:'cJggislE.OO3qX7HZxpxhEWPXvCFzSiUuEMjlUNcG',
    api_key:'5178eb1dea57f68a6e89bcaa0eb9567cabd1203f',
    production: false,

    getDestinations: homeUrl+'website/api_destinations/',
    bookingCheckPayApi: marketplaceUrl + 'booking/pay',
    searchBuses: homeUrl+'website/api_search',
    bookingApi: homeUrl+'website/api_booking/',
    bookingApiV2: homeUrl+'website/api_booking_v2/',
    confirmApi:homeUrl+'website/api_mpesa_query_status/',
    getTrips: homeUrl+'booking/search?search=',
    getHistory: homeUrlTrips+'api/history/',

    // getDestinations: marketplaceUrl+'buses/bms/website?request_call=aliases',
    // searchBuses: marketplaceUrl+'buses/bms/website?request_call=schedules',

    getSeats: marketplaceUrl + 'buses/seats',
    // bookingApi: marketplaceUrl+'booking/',
    // bookingApiV2: marketplaceUrl+'booking/',
    // confirmApi:marketplaceUrl+'website/api_mpesa_query_status/',
    // bookingCheckPayApi: marketplaceUrl + 'booking/pay',
    v2confirm: v2P+'c2b/transaction/status/',
    retrieveEmail: usersApi+'retrieve/',
    resetPassword: usersApi+'password-reset',
    codePass: usersApi + 'code-validate',
    updateCodePass: usersApi + 'password-reset/',
    signUp: usersApi+'registration',
    loginUser:usersApi+'login',
    getProfile: usersApi+'profile',
    usersApiLogin:usersApiLogin,
    // getTrips: marketplaceUrl+'booking/search?search=',
    // getHistory: marketplaceUrlTrips+'api/history/',
    submitCarhire: marketplaceUrl+'booking/bms-website/car-hire',
};
