<app-headerhome class="header-sec"></app-headerhome>

<!-- ================================
    START BREADCRUMB AREA
================================= -->
<section class="breadcrumb-area gradient-bg-gray before-none">
    <div class="breadcrumb-wrap">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between">
                        <div class="section-heading text-left">
                            <h2 class="sec__title">Privacy Policy</h2>
                            <p class="sec__desc font-weight-regular pb-2">Transnumerik</p>
                        </div>
                        <div class="btn-box bread-btns">
                            <a href="/#" class="theme-btn theme-btn-gray mr-2"><i class="la la-bookmark mr-1"></i>PDF</a>
                        </div>
                    </div><!-- end breadcrumb-content -->
                </div><!-- end col-lg-12 -->
            </div><!-- end row -->
        </div><!-- end container -->
    </div><!-- end breadcrumb-wrap -->
    <div class="bread-svg-box">
        <svg class="bread-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none"><polygon points="100 0 50 10 0 0 0 10 100 10"></polygon></svg>
    </div><!-- end bread-svg -->
</section><!-- end breadcrumb-area -->
<!-- ================================
    END BREADCRUMB AREA
================================= -->
<!-- ================================
    START PRIVACY Policy AREA
================================= -->
<section class="job-details-area section--padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-10">
                <div class="form-box">
                <div *ngFor="let section of privacyPolicy; let i = index" class="col-lg-10">
                      <div class="form-title-wrap">
                        <h3 class="title">{{i + 1}}. {{section.name}}</h3>
                      </div>
                      <div class="form-content contact-form-action">
                        <ng-container *ngFor="let item of section.value">
                          <p *ngIf="item.type === 'text'" class="pb-4">{{item.content}}</p>
                          <ul *ngIf="item.type === 'list'">
                            <li *ngFor="let listItem of item.content">{{listItem}}</li>
                          </ul>
                        </ng-container>
                      </div>
                    </div>
                  </div>
            </div><!-- end col-lg-8 -->
        </div><!-- end row -->
    </div><!-- end container -->
</section><!-- end PRIVACY-POLICY-area -->
<!-- ================================
    END PRIVACY-POLICY AREA
================================= -->
<app-footer></app-footer>