<app-headerhome class="header-sec"></app-headerhome>
<div class="theme-page-section theme-page-section-dark">
        <div class="container">
          <div class="theme-search-area _mob-h theme-search-area-white">
            <div class="row">
              <div class="col-md-2 text-white">
                <div class="theme-search-area-header theme-search-area-header-sm">
                  <h1 class="theme-search-area-title">{{ pickUpInit }} - {{ dropOffInit }}</h1>
                  <p class="theme-search-area-subtitle">{{ travelDateInit }}</p>
                </div>
              </div>
              <div class="col-md-10 ">
                <div class="theme-search-area-form" id="hero-search-form">
                    <form  autocomplete="off" novalidate #reactiveForm = "ngForm" (ngSubmit)="submitWebForm(reactiveForm.value)">
                  <div class="row" data-gutter="10">

                        <div class="col-md-3 ">
                          <div class="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                            <div class="theme-search-area-section-inner headerpickup">
                                <!--Using items input-->
                                <ng-select [items]="data" 
                                bindLabel="name" 
                                bindValue="name" 
                                name="pickup"
                                [(ngModel)]="pickup">
                                </ng-select>

                                <span *ngIf=" submitted && f.pickup.errors ">
                                  <span class="text-danger" *ngIf="submitted && f.pickup.errors.required">
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    Please enter pickup location.
                                  </span>
                                </span>

                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 ">
                          <div class="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                            <div class="theme-search-area-section-inner headerdropoff">
                              
                                <ng-select [items]="data" 
                                bindLabel="name" 
                                bindValue="name" 
                                name="dropoff"
                                [(ngModel)]="dropoff">
                                </ng-select>
                                <span *ngIf=" submitted && f.dropoff.errors ">
                                  <span class="text-danger" *ngIf="submitted && f.dropoff.errors.required">
                                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                    Please enter dropoff location.
                                  </span>
                                </span>
                            </div>
                          </div>
                        </div>

                    <div class="col-md-3 ">
                      <div class="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-sm theme-search-area-section-no-border theme-search-area-section-curved">
                        <div class="theme-search-area-section-inner">
                            <i class="theme-search-area-section-icon lin lin-calendar"></i>
                            <input required autocomplete="off" type="text"
                            placeholder="Datepicker"
                            class="theme-search-area-section-input"
                            name="traveldate"
                            [(ngModel)]="traveldate"
                            [minDate]="today"
                            #dp="bsDatepicker"
                            bsDatepicker
                            [bsConfig]="{ isAnimated: true,adaptivePosition: true, dateInputFormat: 'DD-MM-YYYY' }"
                            [bsValue]="bsValue">
                            <span *ngIf=" submitted && f.traveldate.errors ">
                              <span class="text-danger" *ngIf="submitted && f.traveldate.errors.required">
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                Please select traveldate date.
                              </span>
                            </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <button class="theme-search-area-submit _mt-0 _tt-uc theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-sm theme-search-area-submit-primary">Search</button>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="theme-search-area-inline _desk-h theme-search-area-inline-white">
            <h4 class="theme-search-area-inline-title">{{ this.bookingdetails.pickupname}} - {{this.bookingdetails.dropoffname}}</h4>
            <p class="theme-search-area-inline-details">{{this.bookingdetails.traveldate}}</p>
           
          </div>
        </div>
</div>
    <div class="theme-page-section margin-grey">
      <div class="container">
        <div class="bus-listing-page  header-displayed">
            <div class="page-content row">
                
                <div class=" col-md-12" id="search-results-wrapper">
                    <!-- <div class="col-md-12">
                            <h2 *ngIf="isReturnTicket" class="theme-search-area-title text-center">Return Ticket</h2>
                    </div> -->
                <h2 class="city-labels  d-none d-sm-none d-md-block">
                  {{fromPlaceholder}}
                  →
                  {{toPlaceholder}}
                </h2>
                <div class="col-md-12">
                  <h2 *ngIf="isReturnTicket" class="theme-search-area-title text-center">Return Ticket</h2>
              </div>
                <div class="row">
                        <div class="col-lg-12 _mob-h">
                                <ul class="dates ">
                                        <li class="prev-container">
                                            <a *ngIf="!checkDates()" class="prev"  (click)="changeDate(beforeyesterday)" >
                                            ‹
                                    </a></li>
                                    <li  class="yesterday-container">
                                        <a *ngIf="!checkDates()" class="yesterday"  (click)="changeDate(yesterday)">
                                            <strong>
                                                    {{yesterday  | date:'EEEE, d MMM'}}
                                            </strong></a>
                                    </li>
                                    <li class="active-container"><a class="active" [routerLink]="[]"  (click)="changeDate(travelDateInit)" >
                                            <strong>
                                                    {{travelDateInit  | date:'EEEE, d MMM'}}
                                            </strong></a></li>
                                    <li class="tomorrow-container"><a class="tomorrow" [routerLink]="[]" (click)="changeDate(tomorrow)">
                                            <strong>
                                                    {{tomorrow  | date:'EEEE, d MMM'}}
                                            </strong></a></li>
                                    <li class="next-container"><a class="next" [routerLink]="[]" (click)="changeDate(aftertomorrow)">
                                            ›
                                        </a></li>
                                </ul>
                        </div>
                        <div class="col-lg-12 _desk-h">
                          <ul class="dates ">
                                  <li class="prev-container">
                                      <a *ngIf="!checkDates()" class="prev"  (click)="changeDate(beforeyesterday)" >
                                      ‹
                              </a></li>
                              <li  class="yesterday-container">
                                  <a *ngIf="!checkDates()" class="yesterday"  (click)="changeDate(yesterday)">
                                      <strong>
                                              {{yesterday  | date:'d MMM'}}
                                      </strong></a>
                              </li>
                              <li class="active-container"><a class="active" [routerLink]="[]"  (click)="changeDate(travelDateInit)" >
                                      <strong>
                                              {{travelDateInit  | date:'d MMM'}}
                                      </strong></a></li>
                              <li class="tomorrow-container"><a class="tomorrow" [routerLink]="[]" (click)="changeDate(tomorrow)">
                                      <strong>
                                              {{tomorrow  | date:'d MMM'}}
                                      </strong></a></li>
                              <li class="next-container"><a class="next" [routerLink]="[]" (click)="changeDate(aftertomorrow)">
                                      ›
                                  </a></li>
                          </ul>
                  </div>
                </div>
                <div class="theme-search-results search-result-multiselect">

                    <div class="theme-search-results-item search-result" id="search-result-direct">
                        <div class="row">

                            <div class="b col-lg-12 rides-list currency-eur">
                              <div class="sr-head row _mob-h">
                                  <div class="col-lg-4 col-lg-offset-2 time-transfer">
                                      <div class="row">
                                          <div class="col-lg-12 ride-timing">Departs/Arrives
                                              </div>
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="row">
                                          <div class="col-lg-8 col-lg-8 visible-sm visible-lg">Depature Time
                                              </div>
                                          <div class="col-lg-4 col-sm-4 col-md-4 col-lg-4">
                                              <div class="row">
                                                  <div class="col-lg-12 col-sm-4 col-md-12 col-lg-5 total">
                                                      Total
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div id="results-group-container-direct" class="result-date-container">
                                <app-results-loader *ngIf="isResultsLoading"></app-results-loader>
                                <app-nobuses  *ngIf="resultsholder.length == 0"></app-nobuses>
                            <div  *ngFor="let result of results;let i = index" class=" ride-item-pair  row" >
                                
                                <div class="col-lg-12">
                                    <div class="sr-row row  ride--booked js-ride--booked _pd-10">
                                      <div  class="col-lg-4 time-transfer">
                                          <img  class="mobile-images _mt-10" height="90" alt="Bus" src="assets/images/transnumeri-bus.webp">
                                          <br >
                                          <!-- <p *ngIf="!result.fleet.registration_number.includes(fromPlaceholder+'-')" class="text-danger  badge _mb-10">Highway bus</p> -->
                                          <div class=" d-block d-sm-none d-md-block d-block d-md-none  d-block d-lg-none   ">
                                            <small>{{ result.schedule.start_time | convertTime }}</small>
                                       
                                         </div>
                                      </div>
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class=" text-center col-lg-4 visible-sm visible-lg">
                                                    <div class="row">
                                                        <div  class="col-lg-12 ">
                                                          <div class=" ride-stations">
                                                              <div  class="bus-info u-ib u-v-align-middle">
                                                              <h6  class="left-wing city-labels-list u-ib _mr-5">
                                                                 {{ result.route.name.includes("Nairobi(CB)") || result.route.name.includes("Nairobi(CB2)") ? result.route.name.replace("Nairobi(CB", "Nairobi( Country Bus ") :result.route.name }}
                                                              </h6>
                                                              <!-- <div  class="timeline-widget u-ib">
                                                                <div  class="c-timeline-wrapper horizontal">
                                                                  <div  class="label tl first" style="font-size: 20px;"></div>
                                                                  <div  class="label tl last" style="font-size: 20px;"></div>
                                                                  <div  class="c-timeline-booking" style="height: 3px; background-color: rgb(187, 187, 187);">
                                                                  </div>
                                                                  <div  class="label br first" style="font-size: 20px;"></div>
                                                                  <div  class="label br" style="font-size: 20px;"></div>
                                                                  <div  class="label br last" style="font-size: 20px;"></div>
                                                                </div>
                                                              </div> -->
                                                              <!-- <h6  class="right-wing city-labels-list u-ib _ml-5">
                                                                  {{ dropOffInit}}
                                                              </h6> -->
                                                            </div>
                                                          </div>
                                                            <div class="duration ride__duration ride__duration-messages text-center">
                                                                 {{ result.schedule.start_time | convertTime}}
                                                           </div>

                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="col-sm-8 col-lg-8 price-actions">
                                                    <div class="row">
                                                        <div
                                                            class="col-lg-12 col-sm-4 col-md-12 col-lg-5 ">

                                                            <div class="in-cart total"><span
                                                                    class="num pull-right _pb-10">Ksh {{result.seat_price.normal}}</span>
                                                            </div>

                                                        </div>
                                                       
                                                        <div class="col-lg-12 col-sm-8 col-md-12 col-lg-7 action">
                                                           <button type="submit" class="js-ride__action__form__btn reserve"  data-toggle="modal" data-target="#exampleModal" style="box-sizing: border-box;" (click)="selectSeats(result)">Select Seat</button>
                                                           <div class="seats-notice">
                                                                {{result.fleet.fleet_total_seats - (result.fleet.booked_seats.split(",").length - 1)}} seats left
                                                             </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                        <div *ngIf="dataLoading" class="seat-layout-loader"><div class="c-loader"><div class="c-loader-inner"><div class="loader"></div></div></div><span class="close-button ixi-icon-cross"></span></div>
                                          <div  *ngIf="result.hide_from_ui">
                                                <div class="modal left fade" id="exampleModal" tabindex="" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog" role="document">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                 
                                                                    <div class="seat-book-cntr-mobile">
                                                                        <div class="center-wrapper">
                                                                            <div  class="seat-layout">
                                                                                <div class="seat-layout-cntr">
                                                                                    <div class="seat-legends">
                                                                                        <div class="seat-legend u-ib">
                                                                                            <div class="single-seat-legend u-ib ">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                            </div>
                                                                                            <div class="u-ib text">Available</div>
                                                                                        </div>
                                                                                        <div class="seat-legend u-ib">
                                                                                            <div class="single-seat-legend u-ib unavailable">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                            </div>
                                                                                            <div class="u-ib text">Occupied</div>
                                                                                        </div>
                                                                                        <div class="seat-legend u-ib">
                                                                                            <div class="single-seat-legend u-ib selected">
                                                                                                <div class="top"></div>
                                                                                                <div class="left"></div>
                                                                                                <div class="right"></div>
                                                                                                <div class="bottom"></div>
                                                                                                <div class="back"></div>
                                                                                            </div>
                                                                                            <div class="u-ib text">Selected</div>
                                                                                        </div>
            
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true" class="la la-close"></span>
                                                                      </button>
                                                            </div>
                                                             
                                                              
                                                             
                                                            <div class="modal-body">
                                                                <div class=" flex-sm-column flex-row">
                                                                    <div class="seat-book-cntr-mobile  "  *ngIf="result.hide_from_ui">
                                                                      <div class="center-wrapper">
                                                                        <div class="seat-layout-cntr">
                                                                            <div class="seat-layout-heading">SELECT A SEAT</div>
                                                                            <div class="seat-layout">
                                                                                <div class="seat-rows u-ib">
                                                                                    <div class="bus-steering u-ib">
                                                                                        <div *ngFor="let row of seatRows" class="seat-row mt_5">
                                                                                            <ng-container *ngFor="let seat of row">
                                                                                                <div *ngIf="seat; else noSeat" [ngClass]="(result.fleet.seat_numbers.includes(seat.id))?'':'unavailable'" (click)="seatSelect($event,result, seat.id)" class="seat u-ib single-seat">
                                                                                                    <div class="top"></div>
                                                                                                    <div class="left"></div>
                                                                                                    <div class="right"></div>
                                                                                                    <div class="bottom"></div>
                                                                                                    <div class="back"></div>
                                                                                                    <div class="seat-no">{{seat.id}}</div>
                                                                                                </div>
                                                                                                <ng-template #noSeat>
                                                                                                    <div class="no-single-seat"></div>
                                                                                                </ng-template>
                                                                                            </ng-container>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                     </div>
                                                                </div>
                                                            </div>
                                                            <div class="modal-footer sidebar-footer " *ngIf="totalFare > 0">
                                                                <p><b><span  class="text-green price-labels-lg"> Ksh {{totalFare}}</span></b>
                                                                </p>
                                                                <button (click)="submitForm(result)" type="submit" class="btn btn-secondary reserve" data-dismiss="modal" aria-label="Close" [ngClass]="( totalFare > 0) ? 'enabled':'disabled'" >BOOK NOW</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                                 <div class="col-md-12 d-block d-sm-none d-md-block d-block d-md-none  d-block d-lg-none ">
                                                   <div class="book-section" *ngIf="totalFare > 0">
                                                       <div class="bus-booking-summary">
                                                           <div class="summ-heading">Selected Seat </div>
                                                           <span *ngFor="let seatvalue of seatsSelected" class="selected-seats">{{seatvalue}}<b *ngIf="seatsSelected.length > 1">, </b></span>
                                                           <span *ngIf="seatsSelected.length ==0">-</span>
                                                           <div class="fare-dtl-row total">
                                                               <div class="left-wing u-ib u-v-align-middle">Fare: </div>
                                                               <div class="right-wing u-ib u-v-align-middle">
                                                                   <div class="c-price-display u-text-ellipsis " title=""><span class="icon"><i
                                                                               class="ixi-icon-inr icon" aria-hidden="true"></i></span>
                                                                               <span  class="">{{totalFare}}</span>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                           <button (click)="submitForm(result)" type="submit" [ngClass]="( totalFare > 0) ? 'enabled':'disabled'"  class="booking-btn  u-link">BOOK NOW</button>
                                                       </div>
                                                   </div>
                                                  </div>



                                                <div class="u-clear"></div>

                                          </div>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>


                        </div>
                                <div class="row">
                                    <div id="multiselect-error" class="col-lg-12 rides-list" data-group="direct"
                                        style="display: none">
                                        <div class="search-result">
                                            <div class="search-result__not-found">
                                                <div class="search-result-suggestions--cities"></div>
                                                <div class="search-result__not-found__text">Unfortunately there
                                                    are no trips on this route on
                                                    the selected date. Please adjust the date of the trip to get
                                                    results.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
                <div class="offer-section u-ib u-layout-col-2">

                </div>
            </div>
        </div>
      </div>
    </div>
      <div class="theme-page-section theme-page-section-white">
        <div class="container">
          <div class="row row-col-static" id="sticky-parent">
            <div class="col-md-11">
              <div class="theme-search-results _mb-10">
              </div>
            </div>
          </div>
        </div>
      </div>
<app-footer></app-footer>
