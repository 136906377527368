import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  contacts:any;
  socials:any;
  constructor(    private config_service : ConfigService) { }

  ngOnInit() {
    this.config_service.getData().subscribe(data => {
      this.contacts = data.contacts;
      this.socials = data.socials;
    });
  }

}
