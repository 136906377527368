import { Component, OnInit } from '@angular/core';
import { formatDate, PlatformLocation  } from '@angular/common';
import {Router} from "@angular/router"
import { Base64 } from 'js-base64';
import { DataService } from '../../services/index'
import { ConfigService } from '../../services/config.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  today=new Date();
  returning=false;
  top_routes: any[] = [];
  socials: any[] = [];
  contacts:any=[];
  company_name:any='';
  constructor(
    private router:Router,
    private dataService: DataService,
    private config_service : ConfigService
  ) { }

  ngOnInit() {
    this.config_service.getData().subscribe(data => {
      this.top_routes = data.top_routes;
      this.contacts = data.contacts;
      this.company_name = data.company_name
      this.socials = data.socials;
      console.log(this.contacts);
    });
  }




clickTopCity(depature,destination){

  console.log(depature,destination)
  var traveldate = formatDate(this.today,"yyyy-MM-dd","en-KE");
  let kisumu={
      'pickup' : depature,
      'dropoff' : destination,
      'pickupname' :depature,
      'dropoffname' : destination,
      'traveldate' : traveldate,
      'returndate': '',
      'returning' : this.returning
    }

    console.log(kisumu)

    let p=Base64.encode(JSON.stringify(kisumu));
    this.dataService.changeMessage(p);
    this.router.navigate(['/booking', traveldate])

}




  

}
