import { Component, OnInit } from '@angular/core';
import { User } from '../../models/index'
import { UserService } from '../../services/user.service'
import { Router } from '@angular/router';

import { UserSessionService } from '../../sevices/user-session.service'
import { EMPTY } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';
@Component({
  selector: 'app-headerhome',
  templateUrl: './headerhome.component.html',
  styleUrls: ['./headerhome.component.css']
})
export class HeaderhomeComponent implements OnInit {
  isLoggedIn:any;
  user:User;
  api_error:Error;
  loadingStart:boolean = false;
  resMsg:any
  easyLogin:boolean = false;
  contacts:any =[];
  socials:any = [];
  constructor( private users:UserService,private sessionService:UserSessionService, private router:Router, private config_service : ConfigService) {

    this.sessionService.currentMessage.subscribe(message =>{
      if(!message){

      }
      if(message == "nouser"){
        sessionStorage.setItem('isLoggedInEasy','false')
        localStorage.setItem('isLoggedInEasy','false');
        this.isLoggedIn=false;
      }else{
        localStorage.setItem('isLoggedInEasy','true');
        localStorage.setItem('userEasy',JSON.stringify(message));
        this.user=new User().deserialize(JSON.parse(localStorage.getItem('userEasy')))
        this.isLoggedIn=true;
        this.user=new User().deserialize(message)
        // this.getUserProfile(this.user);
        sessionStorage.setItem('isLoggedInEasy','true')
        // sessionStorage.setItem('userEasy',JSON.stringify(message));
        
      }

   
    });

   }

  ngOnInit() {

    this.config_service.getData().subscribe(data => {
      this.contacts = data.contacts;
      this.socials = data.socials;
    });

    this.isLoggedIn = sessionStorage.getItem('isLoggedInEasy');
    this.easyLogin =  this.isLoggedIn
    if(this.isLoggedIn ){
      this.user=new User().deserialize(JSON.parse(sessionStorage.getItem('userEasy')))
      this.getUserProfile(this.user);
    }
  }

  logoutUser(){
    localStorage.removeItem("userEasy");
    sessionStorage.removeItem("userEasy");
    this.isLoggedIn=false;
    sessionStorage.setItem('isLoggedInEasy','false')
    localStorage.setItem('isLoggedInEasy','false');
    this.router.navigateByUrl('/home')
  }
  getUserProfile(user:any){
    this.users.getProfile(user).subscribe(
      response => {
          this.loadingStart=false;
          this.resMsg=response;
          localStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
          sessionStorage.setItem('userEasy',JSON.stringify(this.resMsg.data));
      },
      error => {
        localStorage.removeItem("userEasy");
        sessionStorage.setItem('isLoggedInEasy','false')
        localStorage.setItem('isLoggedInEasy','false');
        this.isLoggedIn=false;

        return EMPTY
      }
    );
  }

}
