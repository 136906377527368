import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {BookingService,HomeService, DataService } from '../services/index'
import {Results } from '../models/index'
import { Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { formatDate, LocationStrategy  } from '@angular/common';
import { Base64 } from 'js-base64';
import * as $ from 'jquery';
import * as moment from 'moment';
import { EMPTY, fromEvent,  } from 'rxjs';
@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.css']
})
export class BookingComponent implements OnInit {
  reactiveForm: UntypedFormGroup;reactiveMobileForm: UntypedFormGroup;pickupForm: UntypedFormGroup;

  public pickupplaceholder: string = 'Boarding Point';
  public dropoffplaceholder: string = 'Dropoff Point';
  public keyword = 'name';
  checkiftoday:boolean;
  public pickplaceholder: string = 'Enter Pick Up';
  public destplaceholder: string = 'Enter Drop Off';
  public historyHeading: string = 'Recently selected';
  today=new Date();
  bsValue = new Date();
  destinationLoading=false;
  beforeyesterday:Date =( d => new Date(d.setDate(d.getDate()-2)) )(new Date);
  yesterday:Date = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date);
  tomorrow:Date = ( d => new Date(d.setDate(d.getDate()+1)) )(new Date);
  aftertomorrow:Date = ( d => new Date(d.setDate(d.getDate()+2)) )(new Date);

  //traveldate:any;
  data = [];cities_data = [];formdata={};
  formdataTravel={};
  formdataReturn={};
  private sub: any;
  res: any = {};
  schedule: any ={};
  routename: any ={};
  pickup:any =[];
  dropoff:any =[];
  seatsSelected: any=[];
  selectedOptionPassengers;
  seats =[];
  seatData:any =[];
  selectedBus:any;
  selectedBusStatus:boolean=false;
  fleet: any ={};
  seat_price: any ={};
  params:any;
  bookingdetails: any;
  results: any = [];
  return_schedule:any;
  resultsholder: any=[];
  seatMobile:any =[];
  isResultsLoading=false;
  showSeats=false;
  changeI=false;
  pickUpInit: string;
  dropOffInit: string;
  travelDateInit: string;
  isUnavailabe=false;
  dataLoading=false;
  isReturnTicket=false;
  isShowingFilter=false;
  totalFare=0;
  returning=false;
  selectedIndex = -1;
  selectedDepatureId:string;
  selectedDestinationId:string;
  fromPlaceholder="From";
  searchDate:string;
  toPlaceholder="To";
  isreturnsearch:boolean;
  traveldate =new Date();
  allSeatArray=[];
  bookedSeatArray=[];
  booked_seat_session_id='';
  seatErrors=false;
  seatDataNew:any =[];
  seatRes:any;
  IsDiscount=false
  availableSeats=[];
  discountAmount:any;
  busSelected:any;
  isBusSelected:boolean =false;
  busOperatorSelected:any;
  currentBusId:any;
  submitted:boolean =false;
  submittedMashPoa:boolean =false;
  isReturnTicketSelected=false;
  finalReturnDateInit:string;
  finalTravelDate: string;
  seatRows: any[][] = [];

 // pickupanddropoff
  pickuplocation:string =""
  dropofflocation:string =""
  pickupreturnlocation:string =""
  dropoffreturnlocation:string =""
  constructor(
    private render:Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private service:BookingService,
    private homeservice:HomeService,
    private dataService:DataService,
    private locationStrategy: LocationStrategy,
    private _fb: UntypedFormBuilder
  ) {
    this.reactiveForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required],
      returndate:[]
    });
    this.reactiveMobileForm = _fb.group({
      pickup:['', Validators.required],
      dropoff:['', Validators.required],
      traveldate:[new Date(), Validators.required],
      returndate:[]
    });

     // pickupanddropoff
     this.pickupForm = _fb.group({
      pickupLocation:['', Validators.required],
    });

   }

   returnClicked = () =>{
    this.returning=true;
  }

  closeReturn = () =>{
    this.returning=false;
  }

  showFilter () {
    this.isShowingFilter=true;

  }
  hideFilter() {
    this.isShowingFilter=false;

  }

       // pickupanddropoff
       get fpickup() { return this.pickupForm.controls; }
       get f() { return this.reactiveForm.controls; }
       

  ngOnInit() {

    this.loadDestinations();
    
    this.dataService.currentMessage.subscribe(message =>{

      if(!message){
          message=localStorage.getItem('search-climax');
      if(!message){
        this.router.navigate(['/index.html'])
      }
    }

     localStorage.setItem('search-climax', message);

      this.bookingdetails = JSON.parse(Base64.decode(message))
      // this.traveldate = this.bookingdetails.traveldate;
      if(this.bookingdetails){
        this.traveldate = new Date(this.bookingdetails.traveldate);

      }
      this.pickUpInit=this.bookingdetails.pickupname;
      this.dropOffInit=this.bookingdetails.dropoffname;
      this.travelDateInit=this.bookingdetails.traveldate;
      this.fromPlaceholder = this.bookingdetails.pickupname;
      this.toPlaceholder = this.bookingdetails.dropoffname;
      this.isreturnsearch=this.bookingdetails.returning;
      // this.searchDate = formatDate(this.travelDateInit,"yyyy-MM-dd","en-KE");
      this.checkDates();
      this.searchBuses(JSON.parse(Base64.decode(message)))
    });

  }

  checkDates=() =>{
    let traveldate = this.bookingdetails.traveldate;
    let today = formatDate(this.today,"yyyy-MM-dd","en-KE");
    var date = new Date(traveldate);
    this.beforeyesterday=new Date(date.setDate(date.getDate()-2));
    this.yesterday=new Date(date.setDate(date.getDate()+1));
    this.tomorrow =new Date(date.setDate(date.getDate()+2))
    this.aftertomorrow =new Date(date.setDate(date.getDate()+2));
      if(traveldate == today)
          return true;
      else
        return false;
  }
  changeDate=(selected) =>{

    this.travelDateInit=formatDate(selected,"yyyy-MM-dd","en-KE");
    this.checkDates();
    let webformdata={
      'pickup' :  this.pickUpInit,
      'dropoff' : this.dropOffInit,
      'pickupname' : this.pickUpInit,
      'dropoffname' :this.dropOffInit,
      'traveldate' :this.travelDateInit,
      'returndate': '',
      'returning' : this.returning
    }

    this.bookingdetails =webformdata
 
    let message=Base64.encode(JSON.stringify(webformdata));
    this.dataService.changeMessage(message);
    localStorage.setItem('search-climax', message);

    this.searchBuses(webformdata);
  }

  interChange=()=>{
    this.changeI=!this.changeI;
    if(this.changeI){

      this.pickUpInit=this.bookingdetails.dropoffname;
      this.dropOffInit=this.bookingdetails.pickupname;
      this.travelDateInit=this.bookingdetails.traveldate;

       const dropOff = this.data.find(city => city.name === this.pickUpInit);
       const pickUp = this.data.find(city => city.name === this.dropOffInit);

      let webformdata={
        'pickup' : dropOff.id,
        'dropoff' : pickUp.id,
        'pickupname' : this.pickUpInit,
        'dropoffname' :this.dropOffInit,
        'traveldate' :this.travelDateInit,
        'returndate': '',
        'returning' : this.returning
      }
      this.bookingdetails =webformdata
      let message=Base64.encode(JSON.stringify(webformdata));
      this.dataService.changeMessage(message);
      localStorage.setItem('search-climax', message);
      this.searchBuses(webformdata);
    }else{
      this.pickUpInit=this.bookingdetails.pickupname;
      this.dropOffInit=this.bookingdetails.dropoffname;
      this.travelDateInit=this.bookingdetails.traveldate;

       const dropOff = this.data.find(city => city.name === this.bookingdetails.dropoffname);
       const pickUp = this.data.find(city => city.name === this.bookingdetails.pickupname);

      let webformdata={
        'pickup' : pickUp.id,
        'dropoff' : dropOff.id,
        'pickupname' : this.pickUpInit,
        'dropoffname' :this.dropOffInit,
        'traveldate' :this.travelDateInit,
        'returndate': '',
        'returning' : this.returning
      }
      this.bookingdetails =webformdata

      let message=Base64.encode(JSON.stringify(webformdata));
      this.dataService.changeMessage(message);
      localStorage.setItem('search-climax', message);

      this.searchBuses(webformdata);

    }
  }

  private loadDestinations= () => {
    let storedCities = localStorage.getItem('transnumerik-cities');
    
    if(storedCities){
      let list: string[] = [];
      this.cities_data = JSON.parse(Base64.decode(storedCities))

      this.cities_data.forEach(element => {
          list.push(element);
      });
    this.data=list;

    }else{
     this.router.navigate(['/'])
    }

  }

  interChangeValues(pickup,dropoff){
    this.fromPlaceholder = pickup;
    this.toPlaceholder = dropoff;
  }

  forMatThisData = (data) => {
    return formatDate(data,"dddd dS, M","en-KE");
  }

  submitWebForm(reactiveForm) {
    this.submitted = true;
    let value = reactiveForm;
    var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;

    let webformdata={
      'pickup' : value.pickup,
      'dropoff' : value.dropoff,
      'pickupname' : value.pickup,
      'dropoffname' :value.dropoff,
      'traveldate' : traveldate,
      'returndate': '',
      'returning' : this.returning
    }
    this.bookingdetails =webformdata
    let message=Base64.encode(JSON.stringify(webformdata));
    localStorage.setItem('search-climax', message);
    this.dataService.changeMessage(message);

    this.searchBuses(webformdata);
  }

  submitMobileForm() {
    this.submitted = true;
     let value = this.reactiveMobileForm.value;

    var traveldate = formatDate(value.traveldate,"yyyy-MM-dd","en-KE");
    value.traveldate=traveldate;


    let mobileformdata={
      'pickup' : value.pickup.id,
      'dropoff' : value.dropoff.id,
      'pickupname' : value.pickup.name,
      'dropoffname' : value.dropoff.name,
      'traveldate' : traveldate,
    }

    this.bookingdetails =mobileformdata
    let message=Base64.encode(JSON.stringify(mobileformdata));

    localStorage.setItem('search-climax', message);

    this.searchBuses(mobileformdata);
  }

  toggleShow(results: any) {
    this.seatsSelected=[];
    this.totalFare=0;
      results.hide_from_ui = ! results.hide_from_ui;
    }

    selectSeats(results: any) {

      this.bookedSeatArray = results.fleet.booked_seats.replaceAll(' ','').split(",");
      this.allSeatArray= results.fleet.seat_numbers.concat(this.bookedSeatArray)
  
        this.seatsSelected=[];
        this.totalFare=0;
        results.hide_from_ui = true;
        this.refreashSeats();
        console.log(results)
        this.createSeatLayout(results.fleet.seat_layout,results)

        }


      getAvailableSeats(query){
        this.seatErrors=false;
        this.service.getSeats(query).subscribe(
        response => {
          this.seatErrors=false;
          this.seatRes = response;
          this.availableSeats =this.seatRes.data.available_seats.available_seat_ids;
          this.seats = this.seatRes.data.available_seats.seats;
          this.booked_seat_session_id =this.seatRes.data.available_seats.booked_seat_session_id;
      
          for(let i=0;i<this.seats.length;i++)
          {
            let iseats =this.seats[i];
            let s =5;
            for(let j=0;j< iseats.length;j++){
                var temp = {s:s,row:i,id: iseats[j].id, type:iseats[j].type, status:iseats[j].status,space_type:iseats[j].space_type,fare:iseats[j].fare};
                this.seatData.push(temp);
                s--;
            }
            let m=5;
            for(let j=0;j< iseats.length;j++){
              var mobiletemp = {m:m,row:i,id: iseats[j].id, type:iseats[j].type, status:iseats[j].status,space_type:iseats[j].space_type,fare:iseats[j].fare};
              this.seatMobile.push(mobiletemp);
              m++;
           }
              this.seatData.sort(function (a, b) {
                return a.s - b.s;
              });
              this.seatMobile.sort(function (a, b) {
                return a.m - b.m;
              });
              this.dataLoading=false;


          }
          const groupBy = key => array =>
          array.reduce((objectsByKeyValue:any, obj:any) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
          }, {});

          const groupByVal = groupBy('s');
          const groupByValMobile = groupBy('row');
          this.seatData = Object.values(groupByVal(this.seatData));
          this.seatMobile = Object.values(groupByValMobile(this.seatMobile));

        },
        error => {
          this.seatErrors=true;
          this.dataLoading =false;

          return EMPTY;

        }
      );
      }

      selectPassOption(id: number,selectedBus) {
        let seatsPrices =selectedBus.seats_types;
        let seatPrice ='0';
        for(let i=0; i<seatsPrices.length; i++){
             if(seatsPrices[i].fare > 0){
               seatPrice=seatsPrices[i].fare;
             }
       }
         //getted from event
  
         this.seatsSelected=[];
         this.selectedOptionPassengers=id
         this.totalFare=parseInt(this.selectedOptionPassengers) * parseInt(seatPrice)
  
         for(let i=0;i<this.selectedOptionPassengers;i++)
         {
             const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
             this.seatsSelected.push({id:random(1, 23),space_type:'business'});
         }
  
       }
       seatSelect(event,result:any,id:any){
        if(event.srcElement.parentElement.classList.contains('unavailable') || event.srcElement.parentElement.classList.contains('no-single-seat')){
        }else{
          if(this.seatsSelected.includes(id)){
            if(event.srcElement.classList.contains('single-seat')){
              event.srcElement.classList.remove("selected");
            }
            this.totalFare=this.totalFare-parseInt(result.seat_price.normal);
            this.seatsSelected = this.seatsSelected.filter(item => item !== id);
            event.srcElement.parentElement.classList.remove('selected');
          }else{
            if(event.srcElement.classList.contains('single-seat')){
              event.srcElement.classList.add("selected");
            }
            this.totalFare=this.totalFare+parseInt(result.seat_price.normal);
            this.seatsSelected.push(id);
            event.srcElement.parentElement.classList.add('selected');
          }
        }
       }

      closeModal(modalId: string) {
        ($(`#${modalId}`) as any).modal('hide');
      }

      openModal(modalId: string) {
          ($(`#${modalId}`) as any).modal('show');
      }

      switchModals(closeModalId: string, openModalId: string) {
          this.closeModal(closeModalId);
          setTimeout(() => this.openModal(openModalId), 500); // Timeout for smooth transition
      }


     refreashSeats(){
      this.dataLoading=false;

      this.service.onSearch(this.bookingdetails).subscribe(
        response => {
          this.dataLoading=false;
          this.res = response;
          this.schedule =  this.results.schedule;
          this.routename =  this.results.route;
          this.pickup =  this.results.pickup;
          this.dropoff =  this.results.dropoff;
          this.fleet =  this.results.fleet;
          this.seat_price =  this.results.seat_price;
        },
        error => {
          // console.log('oops', error);
        }
      );

    }

    submitForm(result) {
      if(this.isReturnTicket){
        this.formdataReturn={
          'pickup' : this.pickUpInit,
          'dropoff' : this.dropOffInit,
          'traveldate': this.travelDateInit,
          'totalfare' : this.totalFare,
          'seatsSelected' : this.seatsSelected,
          'schedule':result.schedule,
          "trip_id": result.trip_id,
          "search_details": this.bookingdetails,
          "route_id": result.route.id,
          "fleet_registration_id": result.fleet.fleet_registration_id,
          "fleet_type_id": result.fleet.fleet_type_id,
        }
      }else{
        this.formdataTravel={
          'pickup' : this.pickUpInit,
          'dropoff' : this.dropOffInit,
          'traveldate': this.travelDateInit,
          'totalfare' : this.totalFare,
          'seatsSelected' : this.seatsSelected,
          'schedule':result.schedule,
          "trip_id": result.trip_id,
          "search_details": this.bookingdetails,
          "route_id": result.route.id,
          "fleet_registration_id": result.fleet.fleet_registration_id,
          "fleet_type_id": result.fleet.fleet_type_id,
        }
      }
      if(this.bookingdetails.returning && !this.isReturnTicket){
        let returnsearch={
          'pickup':this.bookingdetails.dropoff,
          'dropoff':this.bookingdetails.pickup,
          'traveldate':this.bookingdetails.returndate,
        }
        this.isReturnTicket=true;
        this.searchBuses(returnsearch);
      } else if(this.bookingdetails.returning && this.isReturnTicket){
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
        let payreturn=Base64.encode(JSON.stringify(this.formdataReturn));
        let syncData={
            'travel':paytravel,
            'return':payreturn,
            'returning':true
        }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changeMessage(pay);
        this.router.navigate(['/payments', paymessage])

      } else {
        let paytravel=Base64.encode(JSON.stringify(this.formdataTravel));
          let syncData={
            'travel':paytravel,
            'return':'',
            'returning':false
          }
        let pay=Base64.encode(JSON.stringify(syncData));
        let paymessage=Base64.encode("Opps sory mate!");
        this.dataService.changeMessage(pay);
        this.router.navigate(['/payments', paymessage])
      }
    }

    createSeatLayout(layout: string, result) {
      const seatBlocks = layout.split('-').map(Number);
      let currentSeat = 0;
      // Calculate total columns including seats and spaces
      let maxColumns = seatBlocks.reduce((acc, val, idx) => acc + val + (idx < seatBlocks.length - 1 ? 1 : 0), 0);

      while (currentSeat < result.fleet.seat_numbers_2.length) {
          let row = [];
          seatBlocks.forEach((count, index) => {
              for (let i = 0; i < count && currentSeat < result.fleet.seat_numbers_2.length; i++) {
                  row.push(result.fleet.seat_numbers_2[currentSeat++]);
              }
              if (index < seatBlocks.length - 1) row.push(null);  // Add a space between blocks
          });
          // Fill remaining columns with null if the row isn't full
          while (row.length < maxColumns) {
              row.push(null);
          }
          this.seatRows.push(row);
      }
  }

  searchBuses(search){
    this.isResultsLoading=true;
    this.destinationLoading=true;
    this.travelDateInit=search.traveldate;
    this.pickUpInit = search.pickupname;
    this.dropOffInit = search.dropoffname;
    this.pickup =   parseInt(search.pickup)
    this.dropoff =  parseInt(search.dropoff);
    this.interChangeValues(search.pickupname,search.dropoffname);
    this.service.onSearch(search).subscribe(
      response => {
        this.isResultsLoading=false;
        this.destinationLoading=false;
        this.res = response;

        let trips=[];
         this.res.trip_list.forEach(function (trip) {
          if(!trip.hide_from_api){
            trips.push(trip);
          }
        });
        this.results = trips;

        if( this.res.trip_list ) {
          this.resultsholder=trips;
          // this.pickup =   search.pickup;
          // this.dropoff =  search.dropoff;

        }

      },
      error => {
        // console.log('oops', error);
      }
    );

  }

  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e){
    // do something when input is focused
  }


}
